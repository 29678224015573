export default defineComponent({
  setup: function setup() {},
  data: function data() {
    return {
      KPIText: [{
        title: '4 KPIs tracked',
        details: 'are tracked for transportation & data policies'
      }, {
        title: '20% Improvement',
        details: 'on average targeted for 2021 compared to 2020'
      }]
    };
  }
});