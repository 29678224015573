import { render, staticRenderFns } from "./MetricInfo.vue?vue&type=template&id=c2b61772&scoped=true"
import script from "./MetricInfo.vue?vue&type=script&lang=ts"
export * from "./MetricInfo.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b61772",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxMetricInfoText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/MetricInfoText.vue').default,IxButtonWhite: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/button/White.vue').default,IxSubSectionText: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSectionText.vue').default,IxSubSection: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/SubSection.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
